import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.less';
import App from '../components/app.vue';

Framework7.use(Framework7Vue);

new Vue({
  el: '#app',
  render: (h) => h(App),
  data: {
    categories: [],
    filters: [],
    inventory: [],
    invoices: [],
    locations: [],
    management: [],
    notifications:  {
      returns: {
        doctor: 0,
        admin: 0
      },
      inventory: 0,
      doctor: 0,
    },
    orders: [],
    products: [],
    providers: [],
    session: {
      user: {
        id: 0,
        username: '',
        email: '',
        role: '',
      },
      location: {
        id: 0,
        name: ''
      }
    },
    ums: [],
    userRoles: {
      '31': 'medic',
      '33': 'receptioner',
    },
    userRoleId: 0,
    httpRequestPageSize: 1000000,
    virtual: {
      userId: 0,
      locationId: 0,
    },
  },
  computed: {
    filtersFlat() {
      const filters = [];
      this.filters.forEach(el => { el.fValues.forEach(val => {
        filters.push({
          id: val.id,
          name: val.name,
          path: el.name + ' - ' + val.name
        })})
      });
      return filters;
    },
    categoriesFlat(){
      return this.buildFlat(this.categories, 'subcategories');
    }
  },
  methods: {
    fetch(section, data) {
      const app = this.$f7;
      const promises = [];
      const requests = {
        categories: {
          url: 'categorie',
          all: true,
          cb: response => {
            this.categories = this.buildTree(response['data']['results'], null);
          }
        },
        filters: {
          url: 'filtru',
          all: true,
          cb: response => {
            this.filters = response['data']['results'].map(filter => ({
              id: filter['pk'],
              name: filter['nume'],
              fValues: filter['valori_filtre'].map(fValue => ({
                id: fValue['id'],
                name: fValue['nume'],
                path: fValue['nume'],
              }))
            }));
          }
        },
        inventory: {
          url: 'stoc',
          all: true,
          cb: response => {
            this.inventory =  response['data']['results'].map(el => ({
              id: el['id'],
              orders: el['comanda_records'].map(order => ({
                id: order['comanda']['id'],
                itemId: order['id'],
                quantity: order['cantitate'],
                quantityFulfilled: order['cantitate_sent'],
                fulfilled: order['filled'],
                quantitySync: {
                  fulfilled: 0,
                  requested: 0
                },
                user: {
                  username: order['comanda']['user']['username'],
                  email: order['comanda']['user']['email'],
                }
              })),
              returns: [],
              productId: el['produs']['id'],
              quantity: el['cantitate'],
              price: el['pret_ponderat'],
              fulfilled: false,
              product: null
            }));

            this.inventory.forEach(item => {
              if (item.orders) {
                let fulfilled = true;
                item.orders.forEach(order => {
                  if (!order.fulfilled)
                    fulfilled = false;
                });
                item.fulfilled = fulfilled;
              }
            });

            this.inventory.sort(function(a, b){
              return (a.fulfilled === b.fulfilled) ? 0 : a.fulfilled ? 1 : -1;
            });
          }
        },
        invoices: {
          url: 'factura',
          all: true,
          cb: response => {
            this.invoices = response['data']['results'].map(el => {
              const invoice = {
                id: el['id'],
                series: el['serie'],
                number: el['numar'],
                date: el['data'],
                provider: el['furnizor']['id'],
                priceGross: 0,
                priceNet: 0,
                priceVat: 0,
                vat: 19,
                items: [],
                status: el['status'],
                qr: el['qr'],
                type: el['tip_document'],
                info: el['observatii'] || '',
                img: el['image']['full_size']
              };

              if (invoice.status === 2)
                this.providersBadge = true;

              invoice.items = el['items'].map(item => {
                const priceNet = item['pret_net'];
                const priceVat = item['pret_tva'];
                const priceGross = priceNet + priceVat;

                invoice.priceGross += priceGross;
                invoice.priceNet += priceNet;
                invoice.priceVat += priceVat;

                return {
                  id: item.id,
                  productId: item['produs']['id'],
                  product: {},
                  um: item['um']['id'],
                  umStock: item['um_stoc']['id'],
                  quantity: item['cantitate'],
                  quantityStock: item['cantitate_stoc'],
                  quantityReal: item['cantitate_rec'] || 0,
                  priceUnit: item['pret_unitate'],
                  priceNet,
                  priceVat,
                  priceGross,
                }
              });

              return invoice;
            });
          }
        },
        locations: {
          url: 'locatii',
          all: true,
          cb: response => {
            this.locations = response['data']['results'].map(el => ({
              id: el.id,
              name: el['name']
            }));
          }
        },
        management: {
          url: 'gestiune',
          all: true,
          cb: response => {
            this.management = response['data']['results'].map(el => ({
              id: el.id,
              name: el['nume'],
              user: el['fakeuser']['id']
            }));
          }
        },
        orders: {
          url: 'comanda',
          all: true,
          cb: response => {
            this.orders = response['data']['results'].map(el => {
              const date = new Date(Date.parse(el['created']));
              let lockedCount = 0;
              const items = el['items'].map(item => {
                if (item['produs']['locked'])
                  lockedCount++;

                return {
                  id: item['id'],
                  productId: item['produs']['id'],
                  product: {},
                  quantity: item['cantitate'],
                  quantityFulfilled: item['cantitate_sent'],
                  quantityAvailable: item['stoc'],
                  fulfilled: item['filled'],
                  locked: {
                    status: item['produs']['locked'],
                    reason: item['produs']['locked_reason'],
                  },
                  adminOrders: item['ordered'].map(ord => {
                    const dateParsed = new Date(Date.parse(ord['created']));
                    const date = [('0' + dateParsed.getDate()).slice(-2), ('0' + (dateParsed.getMonth() + 1)).slice(-2), dateParsed.getFullYear()].join('.');

                    return {
                      id: ord['id'],
                      quantity: ord['cantitate'],
                      quantityFulfilled: ord['cantitate_sent'],
                      provider: {
                        id: ord['furnizor']['id'],
                        name: ord['furnizor']['nume'],
                      },
                      removed: {
                        status: ord['removed'],
                        reason: ord['reason']
                      },
                      date
                    }
                  })
                }
              });

              return {
                id: el['id'],
                date: [('0' + date.getDate()).slice(-2), ('0' + (date.getMonth() + 1)).slice(-2), date.getFullYear()].join('.'),
                status: el['status'],
                user: {
                  id: el['user']['id'],
                  username: el['user']['username'],
                  email: el['user']['email'],
                },
                location: el['location'],
                lockedCount,
                items,
              }
            });
          }
        },
        products: {
          url: 'produs',
          all: true,
          cb: response => {
            this.products = response['data']['results'].map(el => {
              const filters = el['filtre'];
              const filtersSorted = filters.map(f => f.id).sort();
              const category = el['categorie']['id'];
              const name = el['nume'];

              return {
                id: el.id,
                name: name,
                filters: filtersSorted,
                category: category,
                hash: name.toLowerCase().replace(/\s/g, '') + ',' + category + ',' + filtersSorted.join(','),
                locked: {
                  status: el['locked'],
                  reason: el['locked_reason']
                },
                sterilization: el['sterilizare'],
                um: {
                  id: el['um_stoc']['id'],
                  name: el['um_stoc']['nume'],
                  path: el['um_stoc']['nume']
                },
                price: el['pret'],
                inventoryTracking: el['stoc_worthy'],
                scope: el['scope_int'] === null ? 1 : parseInt(el['scope_int'])
              }
            });
          }
        },
        providers: {
          url: 'furnizor',
          all: true,
          cb: response => {
            this.providers = response['data']['results'].map(provider => ({
              id: provider['id'],
              name: provider['nume'],
              cui: provider['cui'],
              address: provider['adresa'],
              url: provider['site'],
              status: provider['activ'],
              vat: 19,
              contacts: provider['persoane_contact'].map(contact => ({
                id: contact.id,
                name: contact.nume,
                email: contact.email,
                phone: contact.telefon
              }))
            }));
          }
        },
        session: {
          url: 'session-status',
          all: false,
          cb: response => {
            const user = response['data']['user'];
            const location = response['data']['location'];
            this.session = {
              user: {
                id: user['id'],
                username: user['username'],
                email: user['email'],
                role: user['is_superuser'] ? 'admin' : this.userRoles[this.userRoleId]
              },
              location: {
                id: location['id'],
                name: location['name'],
              }
            }
          }
        },
        ums: {
          url: 'um',
          all: true,
          cb: response => {
            this.ums = response['data']['results'].map(el => ({
              id: el['id'],
              name: el['nume'],
              path: el['nume']
            }));
          }
        },
      }

      for (const [k, request] of Object.entries(requests)) {
        if ((section === 'all' && request['all']) || section === k)
          promises.push(
            app.request.promise.json(request['url'] + '/',{ page_size: this.httpRequestPageSize }).then(request['cb'])
          )
      }

      return Promise.all(promises);
    },
    buildFlat(arr, childrenKeyName, path, nodes) {
      let out = [];

      arr.forEach(node => {
        const val = {
          id: node.id,
          name: node.name,
          path: (path ? path + ' - ' : '') + node.name,
          nodes: (nodes ? nodes + '-' : '') + node.id
        }

        out.push(val);

        if (node[childrenKeyName] && node[childrenKeyName].length) {
          out.push(...this.buildFlat(node[childrenKeyName], childrenKeyName, val.path, val.nodes))
        }
      })

      return out
    },
    buildTree(arr, parent) {
      const out = [];

      for(let i in arr) {
        const node = arr[i];
        const val = {
          id: node.id,
          name: node.nume,
          parent_id: node.parent_id,
          subcategories: node.subcategories || []
        }

        if(val.parent_id === parent) {
          const children = this.buildTree(arr, node.id);

          if(children.length) {
            val.subcategories = children;
          }

          out.push(val);
        }
      }
      return out
    },
    formatProductFilters(product){
      return this.filtersFlat.filter(f => product.filters.indexOf(f.id) > -1).map(el => el.name).join(', ')
    },
    getNotifications() {
      this.$f7.request.promise.postJSON(`notifications/`, this.session.user.role === 'admin' ? {
        'admin-notifications': 1
      } : {
        userId: this.virtual.userId || this.session.user.id,
        locationId: this.virtual.locationId || this.session.location.id,
        r: this.session.user.role
      })
        .then((response) => {
          const data = response['data']['notifications'];
          const inventory = data.find(m => m['section'] === 'descarcare-gestiune');
          const doctor = data.find(m => m['section'] === 'doctor-comanda');
          const returnsDoctor = data.find(m => m['section'] === 'doctor-retururi');
          const returnsAdmin = data.find(m => m['section'] === 'admin-retururi');

          this.notifications.inventory = inventory ? inventory['count'] : 0;
          this.notifications.doctor = doctor ? doctor['count'] : 0;
          this.notifications.returns.doctor = returnsDoctor ? returnsDoctor['count'] : 0;
          this.notifications.returns.admin = returnsAdmin ? returnsAdmin['count'] : 0;
        })
    },
    searchTooltip(target) {
      const txt = `Exemple de cautare:
        &nbsp;
        <strong><em>ace</em></strong>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <strong><em>chirurgie</em></strong>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <strong><em>manusi xs</em></strong>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <strong><em>compozit gc 1.5 gr</em></strong>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <strong><em>kendo rosu 25 mm</em></strong>`;
      this.$f7.tooltip.get(target) || this.$f7.tooltip.create({ targetEl: target, trigger: 'click', text: txt, on: { hide: tooltip => tooltip.destroy() } }).show();
    },
    isDuplicate(name, id, dataset) {
      const searchByHash = dataset.find(d => d.name.replace(/\s/g, '').toLowerCase() === name.replace(/\s/g, '').toLowerCase())
      return searchByHash && (!id || (id && id !== searchByHash.id));
    },
  },
  components: {
    app: App
  }
});