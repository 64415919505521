<template>
  <f7-app :params="f7params">
    <f7-appbar :inner="false" v-if="['receptioner', 'medic'].includes(userRole)">
      <f7-toolbar tabbar  position="top" v-if="userRole === 'receptioner'" no-hairline>
        <f7-link href="/invoices" :class="htmlTabClass('invoices')" @click="appbarClick('Facturi', 'invoices')">Facturi</f7-link>
        <f7-link href="/inventory" :class="htmlTabClass('inventory')" @click="appbarClick('Descarcare gestiune', 'inventory')" :tooltip="notifications.inventory ? `Exista ${notifications.inventory} comenzi cu produse in stoc ce pot fi descarcate din gestiune` : ''">
          <span class="display-flex align-items-center">
            Descarcare gestiune
            <f7-badge v-show="notifications.inventory" color="red" class="margin-left-half">{{ notifications.inventory }}</f7-badge>
            </span>
        </f7-link>
        <f7-link href="/inventory-upload" :class="htmlTabClass('inventory-upload')" @click="appbarClick('Retururi medici', 'inventory-upload')" :tooltip="notifications.returns.doctor ? `Exista ${notifications.returns.doctor} retururi care nu au fost aprobate inca` : ''">
          <span class="display-flex align-items-center">
            Retururi medici
            <f7-badge v-show="notifications.returns.doctor" color="red" class="margin-left-half">{{ notifications.returns.doctor }}</f7-badge>
          </span>
        </f7-link>
        <f7-link href="/orders" :class="htmlTabClass('orders')" @click="appbarClick('Comenzi', 'orders')" :tooltip="notifications.doctor ? `Exista ${notifications.doctor} produse care sunt indisponibile` : ''">
          <span class="display-flex align-items-center">
            Comenzi
            <f7-badge v-show="notifications.doctor" color="red" class="margin-left-half">{{ notifications.doctor }}</f7-badge>
          </span>
        </f7-link>
        <f7-link href="/inventory-doctor" :class="htmlTabClass('inventory-doctor')" @click="appbarClick('Gestiune interna', 'inventory-doctor')">Gestiune interna</f7-link>
      </f7-toolbar>

      <f7-toolbar tabbar position="top" v-if="userRole === 'medic'" no-hairline>
        <f7-link href="/orders" :class="htmlTabClass('orders')" @click="appbarClick('Comenzi', 'orders')">Comenzi</f7-link>
        <f7-link href="/inventory-doctor" :class="htmlTabClass('inventory-doctor')" @click="appbarClick('Gestiune', 'inventory-doctor')">Gestiune</f7-link>
      </f7-toolbar>
    </f7-appbar>

    <f7-panel :visible-breakpoint="960" v-if="userRole === 'admin'" left reveal swipe theme-dark>
      <f7-page>
        <f7-block-title>Comenzi</f7-block-title>
        <f7-list>
          <f7-list-item class="panel-close" link="/ordersUnfulfilled">Neprocesate (lista)</f7-list-item>
          <f7-list-item class="panel-close" link="/ordersAdmin">Neprocesate</f7-list-item>
          <f7-list-item class="panel-close" link="/ordersTracking">Urmarire</f7-list-item>
          <f7-list-item class="panel-close" link="/ordersClosed">Finalizate</f7-list-item>
          <f7-list-item class="panel-close" link="/ordersReturns" title="Retururi medici" media-item :badge="$root.$data.notifications.returns.admin > 0 ? $root.$data.notifications.returns.admin : ''" badge-color="red"></f7-list-item>
        </f7-list>

        <f7-block-title>Baza de Date</f7-block-title>
        <f7-list>
          <f7-list-item class="panel-close" link="/providers" media-item title="Furnizori" :badge="providersBadge ? 'Necesita aprobare' : ''" badge-color="red"></f7-list-item>
          <f7-list-item class="panel-close" link="/products">Produse</f7-list-item>
          <f7-list-item class="panel-close" link="/categories">Categorii</f7-list-item>
          <f7-list-item class="panel-close" link="/filters">Atribute</f7-list-item>
          <f7-list-item class="panel-close" link="/ums">Unitati de masura</f7-list-item>
          <f7-list-item class="panel-close" link="/management">Gestiuni interne</f7-list-item>
        </f7-list>

        <f7-block-title>Utilizator</f7-block-title>
        <f7-list>
          <f7-list-item class="panel-close" link @click="checkSetAuth('logout')">Logout</f7-list-item>
        </f7-list>
      </f7-page>
    </f7-panel>
    <f7-view class="safe-areas" main url="/"></f7-view>
    <f7-login-screen id="login">
      <f7-page login-screen>
        <f7-login-screen-title>Login</f7-login-screen-title>
        <f7-list
            form
            @keyup.native.enter="login"
        >
          <f7-list-input
            :value="username"
            label="Username"
            name="username"
            placeholder="Introdu Username"
            type="text"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            :value="password"
            label="Parola"
            name="password"
            placeholder="Introdu Parola"
            type="password"
            @input="password = $event.target.value"
          ></f7-list-input>
          <f7-list-button title="Intra in cont" @click="login()"></f7-list-button>
        </f7-list>
        <f7-list>
          </f7-list>
      </f7-page>
      </f7-login-screen>
  </f7-app>
</template>

<script>
  import routes from '../js/routes.js';
  import Framework7 from "framework7/framework7-lite.esm.bundle";

  export default {
    data() {
      return {
        f7params: {
          name: 'Victoria Admin',
          theme: 'auto',
          routes: routes,
          view:{
            stackPages: false,
          }
        },
        selectedMenuItem: 'providers',
        username: 'admin',
        password: '',//asdqwe123123
        categoriesFlat: [],
        providersBadge: false,
        currentSection: '',
        activeSection: '',
        notifications: this.$root.$data.notifications,
        urlParams: {},
        initialPage: 'ordersUnfulfilled'
      }
    },
    computed: {
      userRole(){
        return this.$root.$data.session.user.role;
      }
    },
    methods: {
      checkSetAuth(authToken) {
        const token = authToken || localStorage.getItem('auth');
        const app = this.$f7;
        const loginScreen = app.loginScreen.get('#login');

        if (token) {
          if (token === 'logout') {
            app.preloader.show();

            localStorage.removeItem('auth');

            window.location.reload(true)
          } else {
            localStorage.setItem('auth', token);
            Framework7.request.setup({
              headers: {
                'Authorization': 'Token '+ token
              }
            });
            loginScreen.close();
            this.fetchData();
          }
        } else {
          loginScreen.open();
        }

        return token;
      },
      fetchData(){
        const app = this.$f7;

        app.preloader.show();

        this.$root.fetch('session')
          .then(() => {
            if (!this.userRole){
              app.preloader.hide();
              app.dialog.alert('Utilizatorul nu este receptioner sau medic!', () => this.checkSetAuth('logout'));
            } else {
              this.$root.fetch('all')
                .then(() => {
                  setInterval(this.$root.getNotifications(), 1e3 * 60 * 5);
                  app.views.main.router.navigate('/' + (this.currentSection || this.initialPage))
                })
                .catch(error => {
                  app.preloader.hide();
                  app.dialog.alert('A aparut o eroare, pagina se va reincarca automat!', window.location.reload(true))
                });
            }
          })
          .catch(error => {
            app.preloader.hide();
            app.dialog.alert('A aparut o eroare, pagina se va reincarca automat!', () => this.checkSetAuth('logout'))
          });
      },
      login(bypassLogin) {
        const app = this.$f7;
        const data = bypassLogin ? {
          'user_id': bypassLogin.userId,
          'location_id': bypassLogin.locationId,
        } : {
          username: this.username,
          password: this.password
        }

        app.preloader.show();
        app.request({
          url: bypassLogin ? 'login-bypass/' : 'login/',
          type: 'POST',
          data: data,
          complete: (xhr, status) => {
            const response = JSON.parse(xhr.response);

            app.preloader.hide();

            if (status !== 200) {
              let errors = [];
              for (let k in response) {
                errors.push(k + ': ' + response[k]);
              }

              app.dialog.alert(errors.join('<br>'), 'Erori de validare');
            } else {
              this.checkSetAuth(response['token']);
            }
          }
        });
      },
      appbarClick(title, section){
        this.currentSection = section;

        window.parent.postMessage({ src: 'financiar', msg: title }, '*');
      },
      htmlTabClass(section){
        return ['padding-horizontal', 'margin-left', 'elevation-3', { 'active' : section === this.currentSection }]
      },
    },
    mounted() {
      this.$f7ready((f7) => {
        const app = this.$f7;

        // @TODO - REMOVE THIS SHIT!
        app.request.setup({
          beforeCreate(params) {
            params.url = (new URLSearchParams(window.location.search).get('url') || 'https://api.dentacloud.ro/api/') + params.url;
          }
        });
        // --

        this.urlParams = new URLSearchParams(window.location.search);
        this.currentSection = this.urlParams.get('section');
        this.$root.$data.userRoleId = this.urlParams.get('r');

        if (this.currentSection) {
          this.login({
            userId: this.urlParams.get('userId'),
            locationId: this.urlParams.get('locationId')
          });

          this.$root.$data.virtual.userId = this.urlParams.get('userId');
          this.$root.$data.virtual.locationId = this.urlParams.get('locationId');
        } else {
          this.checkSetAuth();
        }
      });
    },
  }
</script>