const routes = [
  {
    path: '/category/:id?',
    lazyComponent: 'category',
  },
  {
    path: '/categories',
    lazyComponent: 'categories',
  },
  {
    path: '/contact/:id?',
    lazyComponent: 'contact',
  },
  {
    path: '/filter/:id?',
    lazyComponent: 'filter',
  },
  {
    path: '/filters',
    lazyComponent: 'filters',
  },
  {
    path: '/inventory',
    lazyComponent: 'inventory',
  },
  {
    path: '/inventory-doctor',
    lazyComponent: 'inventory-doctor',
  },
  {
    path: '/inventory-upload',
    lazyComponent: 'inventory-upload',
  },
  {
    path: '/inventory-download/:id',
    lazyComponent: 'inventory-download',
  },
  {
    path: '/invoice/:id?',
    lazyComponent: 'invoice',
  },
  {
    path: '/invoices',
    lazyComponent: 'invoices',
  },
  {
    path: '/management',
    lazyComponent: 'management',
  },
  {
    path: '/order',
    lazyComponent: 'order',
  },
  {
    path: '/orders',
    lazyComponent: 'orders',
  },
  {
    path: '/ordersAdmin',
    lazyComponent: 'ordersAdmin',
  },
  {
    path: '/ordersClosed',
    lazyComponent: 'ordersClosed',
  },
  {
    path: '/ordersReturns',
    lazyComponent: 'ordersReturns',
  },
  {
    path: '/ordersTracking',
    lazyComponent: 'ordersTracking',
  },
  {
    path: '/ordersUnfulfilled',
    lazyComponent: 'ordersUnfulfilled',
  },
  {
    path: '/product/:id?',
    lazyComponent: 'product',
  },
  {
    path: '/products',
    lazyComponent: 'products',
  },
  {
    path: '/provider/:id?',
    lazyComponent: 'provider',
  },
  {
    path: '/providers',
    lazyComponent: 'providers',
  },
  {
    path: '/ums',
    lazyComponent: 'ums',
  },




];

export default routes.map((route) => {
  if (route.lazyComponent) {
    route.async = function(routeTo, routeFrom, resolve, reject) {
      this.app.preloader.show();
      import(/* webpackChunkName: "[request]" */ `./../pages/${route.lazyComponent}.vue`).then((vc) => {
        this.app.preloader.hide();
        resolve({ component: vc.default })
      });
    };
  }
  return route;
});